import $ from 'jquery';
import '@ksedline/turnjs';
import { ScrollTo } from './components/ScrollTo';

$(function() {
  const flipbook = $('.js-flipbook');

  if (flipbook.length) {
    let pageNum = 1,
      timeoutId,
      ratio;
    const
      body = $('body'),
      aspectWrapper = flipbook.find('.js-flipbook-aspect'),
      viewer = flipbook.find('.js-flipbook-viewer'),
      pagesCount = viewer.data('pages-count'),
      src = viewer.data('src'),
      prevBtn = flipbook.find('.js-flipbook-prev'),
      nextBtn = flipbook.find('.js-flipbook-next'),
      pageNumEl = flipbook.find('.flipbook__page-num'),
      toggleViewEl = flipbook.find('.js-flipbook-toggle-view'),
      loading = flipbook.find('.js-loading'),
      linkToPageEl = $('[data-flipbook-page]');

    const setCurrentPage = () => {
      pageNum = viewer.turn('page');
      pageNumEl.text(pageNum);
    };

    const setViewerDimensions = () => {
      const flipWidth = flipbook.width();
      const flipHeight = flipbook.height();
      const neededwidth = Math.round((flipHeight * ratio * 2));
      const neededHeight = Math.round((flipWidth / ratio / 2));

      if (flipHeight < neededHeight ) {
        aspectWrapper.css('width', `${neededwidth}px`).css('height', `${flipHeight}px`);
      } else if (flipWidth < neededwidth) {
        aspectWrapper.css('width', `${flipWidth}px`).css('height', `${neededHeight}px`);
      } else {
        aspectWrapper.css('width', `${flipWidth}px`).css('height', `${flipHeight}px`);
      }
    };

    const resize = () => {
      setViewerDimensions();
      viewer.css('width', '').css('height', '');
      viewer.turn('size', viewer.width(), viewer.height());
    };

    const pageChange = (action) => {
      if (action === 'next') {
        if (pageNum >= pagesCount) return;
      } else if (action === 'previous') {
        if (pageNum <= 1) return;
      }

      viewer.turn(action);
      setCurrentPage();
    };

    const toggleFullscreen = () => {
      flipbook.toggleClass('fullscreen');
      body.toggleClass('no-scroll');

      setTimeout(() => resize(), 10);
    };

    toggleViewEl.each(function() {
      $(this).on('click', e => toggleFullscreen(e));
    });

    prevBtn.on('click', () => pageChange('previous'));

    nextBtn.on('click', () => pageChange('next'));

    document.onkeyup = function(e) {
      const evt = e || window.event;
      if (flipbook.hasClass('fullscreen')) {
        if (evt.key === 'Escape' || evt.key === 'Esc' || evt.keyCode === 27) toggleFullscreen();
        if (evt.key === 'ArrowLeft' || evt.keyCode === 37) pageChange('previous');
        if (evt.key === 'ArrowRight' || evt.keyCode === 39) pageChange('next');
      }
    };

    $(window).on('resize', () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => resize(), 100);
    });

    linkToPageEl.each(function() {
      const link = $(this);

      link.on('click', () => {
        viewer.turn('page', +link.data('flipbook-page'));
        setCurrentPage();
        ScrollTo(flipbook, 150);
      });
    });

    const addPage = (page, book) => {
      if (!book.turn('hasPage', page)) {
        const element = $(`<span><img src="${src+page}.jpg" alt="" draggable="false"></span>`);
        book.turn('addPage', element, page);
      }
    };

    viewer.bind('turning', function(e, initPage) {
      let page = initPage;
      const range = $(this).turn('range', page);
      for (page = range[0]; page <= range[1]; page++)
        addPage(page, $(this));
    });

    const initFlipbook = () => {
      loading.addClass('hidden');
      viewer.removeClass('hidden');
      viewer.turn({
        autoCenter: true,
        pages: pagesCount
      });
    };

    viewer.html(`<span><img src="${src}1.jpg" alt="" draggable="false"></span>`);
    viewer.find('img')
      .on('load', function() {
        ratio = this.naturalWidth / this.naturalHeight;

        setTimeout(() => {
          setViewerDimensions();
          initFlipbook();
          setCurrentPage();
        }, 100);
      }).each(function() {
        if(this.complete) $(this).trigger('load');
      });
  }
});
